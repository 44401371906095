<template>
  <div class="contents-area home-contents">
    <div class="section first-screen">
      <video
        width="100%"
        height="100%"
        preload="auto"
        autoplay="true"
        loop="true"
        muted="muted"
        :poster="'//' + serverLink + listSettings.mainSettings.headerpic"
      >
        <source
          :src="'//' + serverLink + listSettings.mainSettings.video"
          type="video/mp4"
        />
      </video>
      <div class="video-background"></div>
      <div class="container">
        <h1>{{ listSettings.mainSettings.title }}</h1>
        <CountrySelect />
        <div class="payment-icons" style="display: none">
          <img
            v-for="icon in listSettings.mainSettings.payment_icons"
            :key="icon.image"
            :src="'//' + serverLink + icon.image"
            alt=""
          />
        </div>
        <div class="triggers-first">
          <div class="trigger">
            <div class="title">
              {{ listSettings.mainSettings.trigger1_title }}
            </div>
            <div class="subtitle">
              <router-link to="/foreign-registers">{{
                listSettings.mainSettings.trigger1_subtitle
              }}</router-link>
            </div>
          </div>
          <div class="trigger">
            <div class="title">
              {{ listSettings.mainSettings.trigger2_title }}
            </div>
            <div class="subtitle">
              {{ listSettings.mainSettings.trigger2_subtitle }}
            </div>
          </div>
          <div class="trigger">
            <div class="title">
              {{ listSettings.mainSettings.trigger3_title }}
            </div>
            <div class="subtitle">
              {{ listSettings.mainSettings.trigger3_subtitle }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section first-data-section">
      <div class="container">
        <div class="first-data-cont">
          <div class="advantages-cont">
            <FirstAdvantagesList />
          </div>
          <div>
            <div class="h2-title block-title">
              <h2
                class="tablet-visible"
                v-if="isCountriesLoaded && $route.meta.country"
              >
                {{ translations("CountryExamples", [curCountry.text]) }}
              </h2>
              <h2 class="tablet-visible" v-if="!$route.meta.country">
                {{ translations("Examples") }}
              </h2>
            </div>
            <div class="doc-exapmles">
              <DocsList :countryId="0" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReviewsSlider />
    <FaqAccordeon :title="true" />
  </div>
</template>

<script>
import { VUE_APP_BACK_URL, metrikaCode } from "@/config";
import CountrySelect from "@/components/CountrySelect";
import DocsList from "@/components/egrul/DocsList";
import FirstAdvantagesList from "@/components/app/FirstAdvantagesList";
import ReviewsSlider from "@/components/egrul/ReviewsSlider";
//import BottomVideo from "@/components/egrul/BottomVideo";
import FaqAccordeon from "@/components/app/FaqAccordeon";
import { mapGetters, mapActions } from "vuex";

// import style (<= Swiper 5.x)
import "swiper/css/swiper.css";

export default {
  name: "Home",
  metaInfo() {
    return {
      title:
        this.listSettings.mainSettings.meta_title +
        " | " +
        this.listLang.site_name,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.listSettings.mainSettings.meta_desc,
        },
        {
          name: "keywords",
          content: this.listSettings.mainSettings.meta_keywords,
        },
      ],
    };
  },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      showModal: false,
      goalReached: false,
    };
  },
  components: {
    CountrySelect,
    FirstAdvantagesList,
    DocsList,
    ReviewsSlider,
    FaqAccordeon,
    //    BottomVideo,
  },
  computed: {
    ...mapGetters([
      "listSettings",
      "listLang",
      "listTypePurposes",
      "typePurposesLoaded",
      "curTypePurpose",
      "translations",
      "isCountriesLoaded",
      "ListLoggedUser",
      "curCountry",
      "reviewsLoaded",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchCountries",
      "fetchTypePurposes",
      "fetchProductTypes",
      "setCurTypePurpose",
      "refreshSettings",
    ]),
    reachPageGoal() {
      if (this.ListLoggedUser.boughts === 0) {
        if (!this.goalReached) {
          this.$metrika.reachGoal("goalPagesVisit", {
            page: "Главная",
            //        countryCode: this.curCountry.code,
            //        country: this.curCountry.text,
          });
          this.goalReached = true;
        }
      }
    },
  },
  watch: {
    reviewsLoaded() {
      console.log("reviewsLoaded");
      if (this.$route.hash) {
        setTimeout(() => {
          this.$scrollTo(document.querySelector(this.$route.hash), 500);
        }, 500);
      }
    },
  },
  mounted() {
    document.addEventListener("yacounter" + metrikaCode + "inited", () => {
      this.reachPageGoal();
      console.log("metrika inited at home!");
      return true;
    });
    if (this.$metrika) {
      this.reachPageGoal();
    }
    if (this.reviewsLoaded) {
      if (this.$route.hash) {
        setTimeout(() => {
          this.$scrollTo(document.querySelector(this.$route.hash), 500);
        }, 500);
      }
    }
  },
};
</script>